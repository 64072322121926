import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/header/Header';
import Form from './components/form/Form';
import Confirmation from './components/confirmation/Confirmation';
import NotFound from './components/not-found/NotFound';
import './App.css';
import { Helmet } from 'react-helmet';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lng = searchParams.get('lng');
    if (!lng) {
      navigate('/?lng=en');
    }
  }, [location, navigate]);

  return (
    <div className="App">
        <Helmet>
          <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" defer async />
        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
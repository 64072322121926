import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import App from './App';
import './index.css';
import './components/languages/i18n';

const root = createRoot(document.getElementById('root')); // Use createRoot instead of ReactDOM.render

root.render(
  <App />
);
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { S3 } from 'aws-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import './Form.css';

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Use useNavigate for navigation
  const location = useLocation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const turnstileRef = useRef(null);
  const debugOn = JSON.parse(process.env.REACT_APP_DEBUG_ON)
  const [sitekey, setSitekey] = useState(process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY);

  const initializeTurnstile = () => {
    if (window.turnstile) {
      window.turnstile.render(turnstileRef.current, {
        sitekey: sitekey,
        callback: (token) => {
          if (debugOn) {
            console.log("Token: ", token)
          }
          setTurnstileToken(token);
        },
        'error-callback': (error) => {
          if (debugOn) {
            console.error('Turnstile initialization error:', error);
          }

          setError(t('turnstileWidgetError'));
        },
      });
      if (turnstileToken && typeof turnstileToken !== 'string') {
        if (debugOn) {
          console.error('Invalid turnstileToken:', turnstileToken);
        }
        return;
      }
    } else if (debugOn) {
      console.error('Turnstile library is not loaded.');
    }
  };

  useEffect(() => {
    if (sitekey && typeof sitekey !== 'string') {
      if (debugOn) {
        console.error('Invalid sitekey:', sitekey);
      }

      setError(t('invalidTurnstileSiteKey'));
      setSitekey(process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY);
      return;
    }
    if (!window.turnstile) {
      const interval = setInterval(() => {
        if (window.turnstile) {
          clearInterval(interval);
          initializeTurnstile();
          clearInterval(interval);
          return
        }
      }, 500);
    } else {
      initializeTurnstile();
    }
    // eslint-disable-next-line
  }, [sitekey, debugOn, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!turnstileToken) {
      if (debugOn) {
        console.log("Turnstile Token: ", turnstileToken)
      }
      setError(t('turnstileChallengeError'));
      setLoading(false);
      return;
    }

    try {
      // Basic frontend protection
      if (!validateInput()) {
        setLoading(false);
        return;
      }

      // Check honeypot field
      if (e.target.honeypot.value) {
        setError('Oops! Something went wrong.');
        setLoading(false);
        return;
      }

      // Verify Turnstile token with backend
      const tokenValid = await verifyTurnstileToken(turnstileToken);

      if (!tokenValid) {
        setError('Invalid Turnstile token.');
        setLoading(false);
        if (window.turnstile) {
          window.turnstile.reset(turnstileRef.current);
        }
        return;
      }

      // Prepare data
      const formData = {
        id: uuidv4(),
        fullName,
        email,
        phoneNumber,
        question1,
        question2,
        contactMethod
      };

      // Convert data to JSON
      const jsonData = JSON.stringify(formData);

      // Upload JSON file to AWS S3
      await uploadToS3(jsonData);

      sendDataToFacebook();

      // Reset form fields
      setFullName('');
      setEmail('');
      setPhoneNumber('');
      setQuestion1('');
      setQuestion2('');
      setContactMethod('');
      setLoading(false);

      const searchParams = new URLSearchParams(location.search);
      const lng = searchParams.get('lng');

      if (!lng) {
        navigate(`/confirmation?lng=en}`, { state: { formSubmitted: true } });
      }

      // Navigate to confirmation page with state
      navigate(`/confirmation?lng=${lng}`, { state: { formSubmitted: true } });

    } catch (err) {
      if (debugOn)
        console.log("submitError", err)

      setError(t('submitError'));
      setLoading(false);
      if (window.turnstile) {
        window.turnstile.reset(turnstileRef.current);
      }
    }
  };

  const validateInput = () => {
    if (debugOn) {
      console.log("fullName", fullName)
      console.log("email", email)
      console.log("contactMethod", contactMethod)
      console.log("phoneNumber", phoneNumber)
    }
    // Basic validation, you can add more checks as needed
    if (!fullName || !email || !contactMethod) {
      setError(t('errorMessage'));
      return false;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError(t('emailValidationError'));
      return false;
    }

    if (!fullName.trim()) {
      setError(t('fullNameValidationError'));
      return false;
    }

    if (contactMethod && contactMethod === 'phoneNumber' && !phoneNumber) {
      setError(t('phoneNumberErrorMessage'));
      return false;
    }
    return true;
  };

  const uploadToS3 = async (jsonData) => {
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    });

    if (debugOn) {
      console.log("AWS_REGION", process.env.REACT_APP_AWS_REGION)
      console.log("BUCKET_NAME", process.env.REACT_APP_BUCKET_NAME)
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${fullName}-${Date.now()}.json`,
      Body: jsonData
    };

    await s3.upload(params).promise();
  };

  const verifyTurnstileToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/verify-turnstile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ turnstileToken: token }),
      });

      const result = await response.json();

      return response.ok ? result.success : false;
    } catch (error) {
      if (debugOn) {
        console.error('Error verifying Turnstile token:', error);
      }

      return false;
    }
  };

  const digestMessage = async (message) => {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }

  const getCookie = (name) => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  }

  const getBrowserIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      if (response.status === 200) {
        const data = await response.json()
        return data.ip;
      } else {
        if (debugOn) {
          console.log('Error getting the ip:', error);
        }
        return null;
      }
    } catch (error) {
      if (debugOn) {
        console.log('Error getting the ip:', error);
      }
      return null;
    }
  }

  const sendDataToFacebook = async () => {

    const hashedEmail = await digestMessage(email);
    const ipAddress = await getBrowserIpAddress();

    const url = new URL(window.location.href);

    const facebookData = {
      data: [
        {
          event_name: "FormSubmitted",
          event_time: Math.floor(new Date() / 1000),
          action_source: "website",
          event_source_url: url.href,
          event_id: email,
          user_data: {
            fbc: getCookie("_fbc"),
            fbp: getCookie("_fbp"),
            em: [hashedEmail],
            client_ip_address: ipAddress,
            client_user_agent: window.navigator.userAgent,
          }
        }
      ],
      // test_event_code: "TEST64570" // used for testing purpose
    };

    if (debugOn) {
      console.log("facebookData", facebookData)
    }

    try {
      const response = await fetch(`https://graph.facebook.com/${process.env.REACT_APP_FB_API_VERSION}/${process.env.REACT_APP_FB_DATASET_ID}/events?access_token=${process.env.REACT_APP_FB_CONVERSATION_API_KEY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(facebookData),
      });

      if (response.status === 200) {
        const result = await response.json()
        if (debugOn) {
          console.log("Facebook response:", result)
        }
      } else {
        if (debugOn) {
          console.error('Error sending data to Facebook:', response);
        }
      }
    } catch (error) {
      if (debugOn) {
        console.error('Error sending data to Facebook:', error);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="form">
      <input type="text" name="honeypot" style={{ display: 'none' }} /> {/* Honeypot field */}
      <label>
        {t('fullName')}<span className="required">*</span>:
        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
      </label>
      <label>
        {t('email')}<span className="required">*</span>:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </label>
      <label>
        {t('phoneNumber')}:
        <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </label>
      <label>
        {t('question1')}
        <input type="text" value={question1} onChange={(e) => setQuestion1(e.target.value)} />
      </label>
      <label>
        {t('question2')}
        <input type="text" value={question2} onChange={(e) => setQuestion2(e.target.value)} />
      </label>
      <div className="contact-method">
        <label>{t('contactMethod')}<span className="required">*</span>:</label>
        <div className="contact-method-options">
          <label>
            <input
              type="radio"
              value="email"
              checked={contactMethod === 'email'}
              onChange={(e) => setContactMethod(e.target.value)}
              required
            />
            <span>{t('email')}</span>
          </label>
          <label>
            <input
              type="radio"
              value="phoneNumber"
              checked={contactMethod === 'phoneNumber'}
              onChange={(e) => setContactMethod(e.target.value)}
              required
            />
            <span>{t('phoneNumber')}</span>
          </label>
        </div>
      </div>
      <div ref={turnstileRef} className="cf-turnstile"></div>
      <button type="submit" disabled={loading}>{loading ? 'Submitting...' : t('submit')}</button>
      {error && <p className="error">{error}</p>}
    </form>
  );
};

export default Form;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Header.css';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const showTitleAndDescription = location.pathname === '/';

  return (
    <header className="header">
      <img src="logo.png" alt="logo" className="logo" />
      {showTitleAndDescription && (
        <>
          <h1>{t('title')}</h1>
          <p>{t('description')}</p>
        </>
      )}
    </header>
  );
};

export default Header;
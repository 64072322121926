import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Confirmation.css';

const Confirmation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const formSubmitted = location.state?.formSubmitted;
  const debugOn = JSON.parse(process.env.REACT_APP_DEBUG_ON);
  const siteUrl = process.env.REACT_APP_PAGE_TO_REDIRECT;
  const initialSeconds = process.env.REACT_APP_REDIRECT_IN_SECONDS;
  const [seconds, setSeconds] = useState(initialSeconds);
  const siteName = "our Facebook Page"

  useEffect(() => {
    if (debugOn)
      console.log("formSubmitted", formSubmitted)
    // Check if formSubmitted flag is present in the state
    if (!formSubmitted) {
      navigate('/');
    }

    return resetFormSubmittedValue(formSubmitted)
  }, [debugOn, formSubmitted, navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 1) {
          return prevSeconds - 1;
        } else {
          window.location.href = siteUrl;
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000); // Update every second

    return () => clearInterval(timer); // Clear the timer if the component is unmounted
  }, [siteUrl]);

  const resetFormSubmittedValue = (formSubmitted) => {
    formSubmitted = false;
  }

  return (
    <div className="confirmation">
      <h2>{t('confirmation.title')}</h2>
      <p>{t('confirmation.message')}</p>
      <p>{t('confirmation.redirectMessage', { siteName, seconds })}</p>
    </div>
  );
};

export default Confirmation;